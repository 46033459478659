<template>
  <div>
    <van-field
      v-model="displayText"
      v-bind="$attrs"
      readonly
      :disabled="disabled"
      is-link
      @click="handleShow"
    />
    <van-popup v-model="show" position="bottom">
      <van-datetime-picker
        v-bind="$attrs"
        :title="$attrs.label"
        :formatter="formatterFunc"
        v-model="initVal"
        @cancel="cancel"
        @confirm="onConfirm"
        @change="change"
       />
    </van-popup>
  </div>
</template>

<script>
import { parseTime } from '@/utils'
export default {
  name: 'VantDatetimeSelect',
  model: {
    prop: 'selectValue'
  },
  props: {
    selectValue: {
      type: [String, Number, Date],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      show: false,//Popup是否弹出
      initVal: null, //初始选中数据
      resultValue: null
    }
  },
  computed: {
    displayText: {
      get() {
        if (this.resultValue ) {
          return this.resultValue;
        } 
        return ''
      },
      set() {}
    },
    valueFormatStr() {
      let str = '{y}-{m}-{d} {h}:{i}:{s}';
      switch (this.$attrs.type) {
        case "date":
          str = '{y}-{m}-{d}';
          break;
        case "time":
          str = '{h}:{i}:{s}';
          break;
        case "datetime":
          str = '{y}-{m}-{d} {h}:{i}:{s}';
          break;
        case "year-month":
          str = '{y}-{m}';
          break;
        case "month-day":
          str = '{m}-{d}';
          break;
      
        default:
          break;
      }

      return str;
    }
  },
  methods: {
    formatterFunc(type, val) {
      let str = val;
      switch (type) {
        case "year":
          str = val+'年';
          break;
        case "month":
          str = val+'月';
          break;
        case "day":
          str = val+'日';
          break;
        case "hour":
          str = val+'时';
          break;
        case "minute":
          str = val+'分';
          break;
        default:
          break;
      }
      return str;
    },
    handleShow() {
      if (!this.disabled) {
        this.show = true;
      }
    },
    onConfirm (value) {//确定
      this.resultValue = parseTime(value, this.valueFormatStr);
      this.show = !this.show
      this.$emit('confirm', this.resultValue)
    },
    change (picker) {//当数据改变
    },
    cancel () {//取消
      this.show = !this.show
      this.$emit('cancel')
    }
  },
  watch: {
    selectValue: {
      handler(newVal, oldVal) {//监听变化初始赋值
        let value = parseTime(newVal, this.valueFormatStr);
        if(value) {
          this.initVal = new Date(value);
          this.resultValue = value;
        } else {
           this.initVal = null;
          this.resultValue = '';
        }
      },
      immediate: true
    }
  }
}
</script>