<template>
  <page-view title="诉求记录详情" left-arrow>
    <van-cell-group>
      <van-cell title="工单号" :label="form.ywbh?form.ywbh:form.gzdh">{{zfdmFormatter('SQZT',form.gzdzt)}}</van-cell>
    </van-cell-group>

    <van-cell-group title="用户信息">
      <van-cell title="用户编号">{{form.yhbh}}</van-cell>
      <van-cell title="用户名称" title-style="flex-basis: 5em;flex-grow: 0;">
        <span class="text-left" style="display: inline-block;">{{form.yhmc}}</span>
      </van-cell>
      <van-cell title="联系方式">{{form.yhdh}}</van-cell>
      <van-cell title="用户地址" title-style="flex-basis: 5em;flex-grow: 0;">
        <span class="text-left" style="display: inline-block;">{{form.yhdz}}</span>
      </van-cell>
    </van-cell-group>

    <van-cell-group title="诉求信息">
      <van-cell title="诉求时间">{{form.sqsj}}</van-cell>
      <van-cell title="诉求类型">{{zfdmFormatter('SQLX',form.sqlx)}}</van-cell>
      <van-cell title="诉求内容" title-style="flex-basis: 5em;flex-grow: 0;">
        <span class="text-left" style="display: inline-block;">{{form.bxnr}}</span>
      </van-cell>
    </van-cell-group>

    <van-cell-group title="处理信息">
      <van-cell title="处理时间">{{form.cljssj}}</van-cell>
      <van-cell title="处理方案" title-style="flex-basis: 5em;flex-grow: 0;">
        <span class="text-left" style="display: inline-block;">{{form.bxclfa}}</span>
      </van-cell>
      <van-cell title="处理结果" title-style="flex-basis: 5em;flex-grow: 0;">
        <span class="text-left" style="display: inline-block;">{{form.cljg}}</span>
      </van-cell>
    </van-cell-group>

    <van-cell-group title="处理进度" v-if="form.ywbh">
      <van-steps direction="vertical" :active="processList.length - 1" v-if="form.ywbh">
        <van-step  v-for="(item,idx) in processList" :key="idx" class="lchjItem">
          <div class="hjmc">{{item.gnmc}}</div>
          <div v-if="item.spyjList.length" class="lchjItem__content">
            <div v-for="(task, index) in item.spyjList" :key="index" style="margin-bottom: 10px;">
              <p>
                <span class="lchjItem__clr">{{task.spr}}&nbsp;&nbsp;</span>
                <span style="font-weight: bold;">{{task.spyj ? '已审批。': '审批中。'}}</span>
              </p>
              <div v-if="task.spyj">
                意见：{{task.SPYJMC}}&nbsp;&nbsp;
                <span v-if="task.yjsm">【{{task.yjsm}}】</span>
              </div>
            </div>
          </div>
          <div v-else class="lchjItem__content">
            <span style="font-weight: bold;">{{item.endTime ? '已完成' : '处理中'}}&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span class="lchjItem__clr">{{item.sdrmc}}&nbsp;&nbsp;</span>
            <span class="lchjItem__clsj">{{item.endTime}}&nbsp;&nbsp;</span>
            <span>{{item.endTime ? '处理完毕。' : '开始处理。'}}</span>
          </div>
        </van-step>
      </van-steps>
<!--      <div class="timeline" v-else>-->
<!--        <div :class="['timeline-Item', item.hjzt=='1' ? 'is-success': '']" v-for="(item,idx) in bxclProgress"-->
<!--          :key="item.hjid">-->
<!--          <div class="timeline-Line"></div>-->
<!--          <div class="timeline-Header">-->
<!--            <span class="timeline-icon">{{idx+1}}</span>-->
<!--            <h2 class="timeline-title">{{item.hjmc}}</h2>-->
<!--          </div>-->
<!--          <div class="timeline-Content">-->

<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </van-cell-group>
  </page-view>
</template>

<script>
import { mapGetters } from 'vuex';

import YhbhSelect from '@/components/YhbhSelect/index.vue';
import VantSelect from '@/components/VantSelect/index.vue';
import VantDatetimeSelect from '@/components/VantDatetimeSelect/index.vue';

import {
  zfdmManageService_getZfdmByDmzl
} from '@/api/zfdm'
import { gzdGzsl_add } from '@/api/psdmszhwh/zhwhschema/GzdGzslAPI';
import {gzdxxService_getGzdLcgzb} from "@/api/psdmsgdgl/service/GzdxxServiceAPI";
import {Step, Steps} from "vant";

export default {
  name: 'bxjlxq',
  components: {
    YhbhSelect,
    VantSelect,
    VantDatetimeSelect,
    [Steps.name]: Steps,
    [Step.name]: Step,
  },
  data() {
    return {
      form: {
        yhbh: '',
        yhmc: '',
        yhdz: '',
        yhdh: '',
        sqlx: '0',
        bxnr: ''
      },
      zfdmOptions: {
        'SQLX': [], // 诉求类型
        'SQZT': [], // 诉求状态
      },
      processList:[],
    }
  },
  computed: {
    ...mapGetters(['jgbm','czyh']),
    bxclProgress() {
      let gzdzt = this.form.gzdzt;
      let list = [];
      for(let i = 0 ; i < this.zfdmOptions['SQZT'].length; i++) {
        const item = this.zfdmOptions['SQZT'][i];
        list.push({
          hjid: item.dmbh,
          hjmc: item.dmnr.split("-")[1],
          hjzt: gzdzt >= item.dmbh ? '1':'0'
        })
      }
      return list
    }
  },
  methods: {
    yhbhChangeHandle(yhxx) {
      this.form.yhbh = yhxx.yhbh;
      this.form.yhmc = yhxx.yhmc;
      this.form.yhdz = yhxx.yhdz;
      this.form.yhdh = yhxx.lxsj || yhxx.lxdh;
    },
    handleIsNilChange(val) {
      if (val == '1') {
        this.form.yhbh = '非系统用户';
      } else {
        this.form.yhbh = '';
        this.form.yhmc = '';
        this.form.yhdh = '';
        this.form.yhdz = '';
      }
    },
    resetForm() {
      this.form = {
        isNil: '0',
        yhbh: '',
        yhmc: '',
        yhdz: '',
        yhdh: '',

        sqsj: new Date(),
        yhsqlx: '0',
        bxnr: ''
      };
    },
    fetchZFDM(dmzl) {
      zfdmManageService_getZfdmByDmzl(dmzl).then(res => {
        this.zfdmOptions[dmzl] = res.content;
      });
    },
    zfdmFormatter(dmzl, value) {
      let zfdmArr = this.zfdmOptions[dmzl].filter(item => item.dmbh == value);
      if (zfdmArr.length) {
        return zfdmArr[0].dmnr.split("-")[1];
      } 
      return '';
    },
    fetchProcessData() {
      if (!this.form.qdrq || !this.form.ywbh) {
        return false;
      }
      this.processList = [];
      let loading = this.$toast.loading();
      gzdxxService_getGzdLcgzb(this.form.qdrq, this.form.ywbh).then(res => {
        loading.clear();
        if (res.content.status == 200) {
          this.processList = res.content.data;
        }
      }).catch(err => {
        console.log(err);
        loading.clear();
      });
    },
  },
  mounted() {
    console.log(this.$route)
    if (this.$route.query && this.$route.query.gzdh) {
      this.form = this.$route.query;
      this.fetchProcessData();
    }
    for(let dmzl in this.zfdmOptions) {
      this.fetchZFDM(dmzl);
    }
  }
}
</script>


<style lang="scss" scoped>

.flex-2 {
  flex: 2;
}
.timeline /deep/ {
  margin: 10px;
  .timeline-Item {
    position: relative;
    padding: 10px 0 20px;
    box-sizing: border-box;
  }
  .timeline-Header {
    position: relative;
    height: 30px;
  }
  .timeline-title {
    font-size: 14px;
    padding-left: 30px;
  }
  .timeline-icon {
    position: absolute;
    width: 1em;
    height: 1em;
    top: 0;
    left: 0;
    color: #333;
    border-radius: 50%;
    text-align: center;
    background-color: #ededed;
    margin-right: 8px;
    z-index: 1;
    font-size: 14px;
    padding: 2px;
  } 
  .timeline-Item.is-success .timeline-icon {
    background-color: green;
    color: #fff;
  }
  .timeline-Item.is-success .timeline-Line {
    background-color: green;
  }
  .timeline-Item.is-success .timeline-title {
    color: green;
  } 

  .timeline-Line {
    position: absolute;
    top: 10px;
    left: 0.5em;
    width: 2px;
    bottom: -10px;
    background: #c0c4cc;
    z-index: 0;
  }

  .timeline-Item:last-child .timeline-Line {
    display: none;
  }
}
</style>