// WaGen___HASHKEY__1fdc0240_2021-05-10 14:52:59 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
工作流运行环节图详细信息
*/
export function gzdxxService_getTaskDiagramInfo(qdrq,ywbh, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/getTaskDiagramInfo',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh //Long
			}
		}
	})
}

/* ---
获取功能所对应的权限列表
*/
export function gzdxxService_GetQuXianListByGnid(jgbm,gnid, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/GetQuXianListByGnid',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				gnid : gnid //String
			}
		}
	})
}

/* ---
根据功能ID获取工单转发对应的流转方向值State
*/
export function gzdxxService_GetTransStateByGnid(qdrq,ywbh,ywlb,gnid, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/GetTransStateByGnid',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh, //Long
				ywlb : ywlb, //String
				gnid : gnid //String
			}
		}
	})
}

/* ---
封装功能权限列表
*/
export function gzdxxService_GetQuXianList(jgbm,ywfl,ywlb, pagination, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/GetQuXianList',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				ywfl : ywfl, //String
				ywlb : ywlb //String
			},
			pagination : pagination
		}
	})
}

/* ---
工作流已经完成的任务
*/
export function gzdxxService_GetWcTaskList(qdrq,ywbh, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/GetWcTaskList',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh //Long
			}
		}
	})
}

/* ---
工作流回退
*/
export function gzdxxService_ReturnTask(qdrq,ywbh,nodeId, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/ReturnTask',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh, //Long
				nodeId : nodeId //String
			}
		}
	})
}

/* ---
根据搜索条件获取个人待办
*/
export function gzdxxService_getWdxxByQuery(ywfl,ywlb,schj,ywbh,gzdsm,pageNo,pageSize, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/getWdxxByQuery',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				ywfl : ywfl, //String
				ywlb : ywlb, //String
				schj : schj, //String
				ywbh : ywbh, //Long
				gzdsm : gzdsm, //String
				pageNo : pageNo, //Integer
				pageSize : pageSize //Integer
			}
		}
	})
}

/* ---
获取工单数量
*/
export function gzdxxService_getGzdNum(meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/getGzdNum',
		method : 'post',
		headers : {
		},
		data : {
		}
	})
}

/* ---
通过查询条件获取岗位待办
*/
export function gzdxxService_getGwxxByQuery(ywfl,ywlb,schj,ywbh,gzdsm,pageNo,pageSize, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/getGwxxByQuery',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				ywfl : ywfl, //String
				ywlb : ywlb, //String
				schj : schj, //String
				ywbh : ywbh, //Long
				gzdsm : gzdsm, //String
				pageNo : pageNo, //Integer
				pageSize : pageSize //Integer
			}
		}
	})
}

/* ---
工作流运行环节图
*/
export function gzdxxService_getTaskDiagram(qdrq,ywbh, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/getTaskDiagram',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh //Long
			}
		}
	})
}

/* ---
工作流所有环节信息
*/
export function gzdxxService_queryHjmc(jgbm,ywlb, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/queryHjmc',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				ywlb : ywlb //String
			}
		}
	})
}

/* ---
新建工作单
*/
export function gzdxxService_startTask(gzdGzdxx,map, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/startTask',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				gzdGzdxx : gzdGzdxx, //GzdGzdxx
				map : map //Map<String,Object>
			}
		}
	})
}

/* ---
工作单新建忽略认证
*/
export function gzdxxService_startTaskT(gzdGzdxx,map, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/startTaskT',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				gzdGzdxx : gzdGzdxx, //GzdGzdxx
				map : map //Map<String,Object>
			}
		}
	})
}

/* ---
工作单转发
*/
export function gzdxxService_gzdxxTrans(qdrq,ywbh,czy,Assigne,map, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/gzdxxTrans',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //String
				ywbh : ywbh, //String
				czy : czy, //String
				Assigne : Assigne, //String
				map : map //Map<String,Object>
			}
		}
	})
}

/* ---
工作单转发忽略认证
*/
export function gzdxxService_gzdxxTransT(qdrq,ywbh,czy,Assigne,map, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/gzdxxTransT',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //String
				ywbh : ywbh, //String
				czy : czy, //String
				Assigne : Assigne, //String
				map : map //Map<String,Object>
			}
		}
	})
}

/* ---
工作单委托代办
*/
export function gzdxxService_gzdWtdb(qdrq,ywbh,dbry, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/gzdWtdb',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //String
				ywbh : ywbh, //String
				dbry : dbry //String
			}
		}
	})
}

/* ---
工作单锁定
*/
export function gzdxxService_gzdxxSd(qdrq,ywbh, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/gzdxxSd',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //String
				ywbh : ywbh //String
			}
		}
	})
}

/* ---
工作单解锁
*/
export function gzdxxService_gzdxxJs(qdrq,ywbh, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/gzdxxJs',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //String
				ywbh : ywbh //String
			}
		}
	})
}

/* ---
工作单回退
*/
export function gzdxxService_gzdxxHt(qdrq,ywbh,ObjGnid,Assigne, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/gzdxxHt',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //String
				ywbh : ywbh, //String
				ObjGnid : ObjGnid, //String
				Assigne : Assigne //String
			}
		}
	})
}

/* ---
作废工作单
*/
export function gzdxxService_gzdxxZf(ywlb,qdrq,ywbh, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/gzdxxZf',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				ywlb : ywlb, //String
				qdrq : qdrq, //String
				ywbh : ywbh //String
			}
		}
	})
}

/* ---
处理工作单信息
*/
export function gzdxxService_clgzdxx(gzdGzdxxList, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/clgzdxx',
		method : 'post',
		headers : {
		},
		data : {
			param : gzdGzdxxList //List<GzdGzdxx>
		}
	})
}

/* ---
处理业扩流程数据
*/
export function gzdxxService_clyklcsj(gzdGzdxxList, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/clyklcsj',
		method : 'post',
		headers : {
		},
		data : {
			param : gzdGzdxxList //List<GzdGzdxx>
		}
	})
}

/* ---
处理算费流程数据
*/
export function gzdxxService_clsflcsj(gzdGzdxxList, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/clsflcsj',
		method : 'post',
		headers : {
		},
		data : {
			param : gzdGzdxxList //List<GzdGzdxx>
		}
	})
}

/* ---
违约金转发前校验
*/
export function gzdxxService_isExistWyjjmUser(ywbh,qdrq, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/isExistWyjjmUser',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				ywbh : ywbh, //Long
				qdrq : qdrq //Date
			}
		}
	})
}

/* ---
会签或审批意见保存
*/
export function gzdxxService_spyjBc(gzdSpyj, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/spyjBc',
		method : 'post',
		headers : {
		},
		data : {
			param : gzdSpyj //GzdSpyj
		}
	})
}

/* ---
查询工作单流程跟踪表
*/
export function gzdxxService_getGzdLcgzb(qdrq,ywbh, meta) {
	return fetch({
		url : 'psdmsgdgl/service/GzdxxService/getGzdLcgzb',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //String
				ywbh : ywbh //String
			}
		}
	})
}

